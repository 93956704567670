var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',{staticStyle:{"padding":"0px"}},[_c('MutationForm',{attrs:{"no-reset":"","mutation":"createLink","constants":{ environmentId: _vm.environmentId },"submitButtonText":"Crear","submitButtonIcon":"add","submitButtonColor":_vm.accentColor,"environmentVariables":{ environmentId: _vm.environmentId },"schema":{
        environmentId: {
          __graphQLType: 'ID'
        },
        title: {
          label: 'Nombre',
          type: 'string',
          description: 'Debe ser único y reconocible.',
          newDesign: true,
        }
      }},on:{"success":function($event){return _vm.$emit('create', $event)},"error":function($event){return _vm.$emit('error', $event)}}},[_c('v-btn',{attrs:{"slot":"left-buttons","dark":"","color":"#59a4f4"},on:{"click":function($event){return _vm.$emit('dismiss')}},slot:"left-buttons"},[_vm._v("Cancelar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }